import React, {Component, Fragment, useEffect, useState} from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Header from '../Header';
import {useSelector} from "react-redux";
import {MDBBtn} from "mdbreact";
import {getServiceUrls} from "../../Epics/ServiceUrls";
import LoadingSpinner from "../LoadingSpinner";
import {Button} from "@mui/material";
import useServiceUrls from "../../Utils/UseServiceUrls";
import useUsageId from "../../Utils/UseUsageId";

export default function FormSetupComplete(props) {
    const redirectUrl = useSelector(state => state.session.RedirectUrl);
    const domain = useSelector(state => state.session.domain);
    const connectorUid = useSelector(state => state.session.ProvisionSession.ConnectorUid)
    const sessionKey = useSelector(state => state.login.key)
    const { serviceUrlsStatus, serviceUrls, serviceUrlsError } = useServiceUrls(domain);
    const {usageId, status, error} = useUsageId(serviceUrls, sessionKey)
    
    const redirect = () => {
        let url = redirectUrl;
        if (!url.startsWith('http'))
            url = 'https://' + url;
        window.location.assign(url)
    }
    
    const redirectToDirector = (e) => {
        e.preventDefault()
        let url = serviceUrls.DirectorUrl;
        if (!url.startsWith('http'))
            url = 'https://' + url;
        url = `${url}/c_insight/insight.aspx?UsageId=${usageId}`
        window.location.assign(url)
    }
    
    return (
        <Fragment>
            <Header title="Setup Complete" />
            <Jumbotron>
                <h1>Setup Complete!</h1>
                <p>
                    Thanks for choosing bLoyal!
                </p>
                {redirectUrl &&
                    // <Button id="finish" className='activate-provider-button next-btn' onClick={redirect} >Finish</Button>
                    <a href={redirectUrl}> Finish </a> 
                }
                {!redirectUrl &&
                    <>
                        <p>
                            Close this page to begin using bLoyal
                        </p>
                        <p>Someone from our Team will contact you shortly to finish the integration with your POS System</p>
                        {(status === 'loading' || serviceUrlsStatus === 'loading') && <LoadingSpinner />}
                        {usageId && connectorUid.toLowerCase() !== "2e1e3eb5-156c-4344-a097-13b8ff3fd76a" && <Button id='toDirector' className='link-to-director-button next-btn' onClick={redirectToDirector} >Launch bLoyal Director</Button>}
                    </>
                }
            </Jumbotron>
        </Fragment>
    )
}